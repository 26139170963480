@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,100;9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900&family=Lato:wght@100;300;400;700;900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap");

* {
  font-family: "DM Sans", sans-serif !important;
}

body {
  margin: 0;
  font-family: "DM Sans", sans-serif !important;
}

.ql-container {
  height: calc(100% - 43px) !important;
}
.ql-toolbar.ql-snow {
  border-radius: 1rem 1rem 0rem 0rem;
}

.ql-container.ql-snow {
  border-radius: 0rem 0rem 1rem 1rem;
}

/* scroll bar style */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  /* background: rgb(50, 50, 50); */
  background-color: light-dark(rgb(234, 234, 234), rgb(50, 50, 50));
}
::-webkit-scrollbar-thumb {
  background: light-dark(#c0c0c0, #686868);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: light-dark(#555, #555);
}

/* DataGrid.css */
@media (max-width: 599px) {
  .css-n3z9fz-MuiPopper-root-MuiDataGrid-panel {
    transform: translate3d(0px, 185px, 0px) !important;
    overflow: hidden;
  }

  .MuiDataGrid-filterFormColumnInput {
    width: 50px !important;
  }
  .MuiDataGrid-filterFormValueInput {
    width: 100px !important;
  }
}

.css-zylse7-MuiButtonBase-root-MuiIconButton-root {
  background-color: #07a4fc !important;
  color: white !important;
  margin-left: 5px !important;
}

.css-zylse7-MuiButtonBase-root-MuiIconButton-root.Mui-disabled {
  background-color: #3788d880 !important;
  color: rgba(235, 235, 235, 0.74) !important;
}

.css-wop1k0-MuiDataGrid-footerContainer {
  display: flex;
  flex-direction: row;
  justify-content: center !important;
}
.css-levciy-MuiTablePagination-displayedRows {
  font-weight: bold !important;
}
.css-i4bv87-MuiSvgIcon-root {
  font-size: 25px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
  appearance: textfield; /* Other non-WebKit browsers */
}

/* a global style for Accordion */
.Mui-expanded {
  margin: 0 !important;
}
.css-1x2al7b-MuiPaper-root-MuiAccordion-root {
  &::before {
    display: none !important;
  }
}
.css-1vvq7mh-MuiPaper-root-MuiAccordion-root {
  &::before {
    display: none !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
